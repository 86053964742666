import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import "./AboutUsPage.css";
import "../../components/PageBannerComponent/PageBanner.css"
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import PageBanner from "../../components/PageBannerComponent/PageBanner";
import DefaultButton from "../../components/DefaultButtonComponent/DefaultButton";
import character from '../../images/principles/character.png';
import capacity from '../../images/principles/capacity.png';
import commitment from '../../images/principles/commitment.png';
import time from '../../images/principles/time.png';
import trust from '../../images/principles/trust.png';
import transparency from '../../images/principles/transparency.png';
import vision from '../../images/vision.png';
import mission from '../../images/mission.png';
import quality from '../../images/Policies/quality.png';
import ncd from '../../images/Policies/ncd.png';
import cashes from '../../images/Policies/cashes.png';
import qualitypdf from "../../images/Policies/quality.pdf";
import ncdpdf from "../../images/Policies/ncd.pdf";
import cashespdf from "../../images/Policies/cashes.pdf";
import ManagementModal from "../../components/ManagementModalComponent/ManagementModal";
import { useEffect } from "react";
import { useLocation } from "react-router";
import lawrence from '../../images/Management/lawrence.jpg'
import nneka from '../../images/Management/nneka.png'
import ashok from '../../images/Management/ashok.png'
import elena from '../../images/Management/elena.jpg'
import gmd from '../../images/Management/gmd.jpg'
import charles from '../../images/Management/charles.jpg'
import christian from '../../images/Management/christian.jpg'
import daisy from '../../images/Management/daisy.png'
import dango from '../../images/Management/dango.jpg'
import igilar from '../../images/Management/igilar.jpg'
import iyenemi from '../../images/Management/iyenemi.png'
import nwulary from '../../images/Management/nwulary.jpg'
import aderopo from '../../images/Management/aderopo.jpg'
import varghese from '../../images/Management/varghese.jpg'
import chidiebele from '../../images/Management/chidiebele.jpg'
import emuesiri from '../../images/Management/emuesiri.png'
import epiimtce from '../../images/epiimtce.png';

function AboutUsPage() {
    let clickedLink = localStorage.getItem('clickedLink')
    console.log(localStorage.getItem('clickedLink'))
    const principlesRef = useRef()
    const managementRef = useRef()
    const historyRef = useRef()
    const policiesRef = useRef()
    console.log(clickedLink)
    const location = useLocation();
    var myWidth = 0, myHeight = 0;
    if (typeof (window.innerWidth) == 'number') {
        //Non-IE
        myWidth = window.innerWidth;
        myHeight = window.innerHeight;
    } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
        //IE 6+ in 'standards compliant mode'
        myWidth = document.documentElement.clientWidth;
        myHeight = document.documentElement.clientHeight;
    } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
        //IE 4 compatible
        myWidth = document.body.clientWidth;
        myHeight = document.body.clientHeight;
    }
    console.log('Width = ' + myWidth);
    console.log('Height = ' + myHeight);

    useEffect(() => {

        console.log('here')
        // window.scrollTo(0, 0);
        if (clickedLink == 'principles') {
            principlesRef.current.scrollIntoView({ behavior: 'smooth' })
            console.log(clickedLink);
            console.log(localStorage.getItem('clickedLink'));
        } else if (clickedLink == 'management') {
            managementRef.current.scrollIntoView({ behavior: 'smooth' })
            console.log(clickedLink)
        } else if (clickedLink == 'history') {
            historyRef.current.scrollIntoView({ behavior: 'smooth' })
            console.log(clickedLink)
        } else if (clickedLink == 'policies') {
            policiesRef.current.scrollIntoView({ behavior: 'smooth' })
            console.log(clickedLink)
        } else window.scrollTo(0, 0);
    }, [location]);
    return (
        <div className="about-us-whole">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Who We Are</title>
                <meta name="description" content="PE Energy Limited is a leading African company, specializing in Engineering, Procurement, Integration, Installation, and Maintenance across the following growth engines- Sales and Services Solution (Valves & Actuation, Flow Measurement Solutions, Flow Drives (Rotating Equipment), Automation and Controls, and the entire range of our domain solution -Water and Process Technologies); Engineering Design, Procurement, Construction, Installation and Maintenance (EPiiMtce); and with the purpose of providing top-quality services in the above fields to all our customers." />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <NavBar />
            {/* <PageBanner pageImage="pe-sign" pageTitle="Why PE Energy?" pageDesc1="PE Energy Limited is a leading African company, specializing in Engineering, Procurement, Integration, Installation, and Maintenance across the following growth engines- Sales and Services Solution (Valves & Actuation, Flow Measurement Solutions, Process and Power Solutions, Exploration and Production, Automation and Controls, and the entire range of our domain solution -Water and Process Technologies); Engineering Design, Procurement, Construction, Installation and Maintenance (EPiiMtce); and with the purpose of providing top-quality services in the above fields to all our customers." 
            pageDesc2="PE Energy takes pride in its highly qualified and trained Sales and Field Service Engineers, whose long experience and deep understanding of the industry allow them to offer optimal consulting and execution of the most complex engineering solutions." /> */}
            <div className="col-12 px-0 page-banner pe-sign">
                <div className="col-12 col-md-5">
                    <h1 className="mb-5">Who We Are</h1>
                    {/* <p>PE Energy takes pride in its highly qualified and trained Sales and Field Service Engineers, whose long experience and deep understanding of the industry allow them to offer optimal consulting and execution of the most complex engineering solutions.</p> */}
                </div>
            </div>
            <div className="col-12 pageDesc">
                <div className='text-con col-12 col-md-7 mx-auto py-5'>
                    <p className='desc1'>PE Energy Limited is a Total Solution provider of Flow Control (Valves & Actuation), Measurement Solution, Flow Drives (Rotating Equipment), and Supply Chain Solution.</p>
                    <p className='desc2'>Powered by Our Life Cycle Model: Engineering Procurement, Integration, Installation and Maintenance (<img className="epiimtce-logo margin-top-minus-8" src={epiimtce} alt="epiimtce" />) Model,</p>
                </div>
            </div>
            <div ref={principlesRef} id="aboutus-principles" className="principles mb-5 col-12">
                <div className="col-12 text-center mt-5">
                    <h1 className="section-title mb-5">Our Bow-Tie <span classname="">Principles</span></h1>
                </div>
                <div className="col-12 col-md-10 mx-auto row mx-0 pt-5">
                    {/* <div className="col-12 col-md-6 row mx-auto text-center">
                        <h5 className="color-brand-blue my-5 h5-correction">Our Three C's</h5>
                        <div className="col mx-3 individual-principles">
                            <img className="" src={character} alt="character" />
                            <p className="mt-3 color-brand-blue">Character</p>
                        </div>
                        <div className="col mx-3 individual-principles">
                            <img className="" src={capacity} alt="capacity" />
                            <p className="mt-3  color-brand-blue">Capacity</p>
                        </div>
                        <div className="col mx-3 individual-principles">
                            <img className="" src={commitment} alt="commitment" />
                            <p className="mt-3  color-brand-blue">Commitment</p>
                        </div>
                        <h6 className="col-10 mx-auto mt-4">We believe that to enhance our brand as a major automation and control solutions company, it is vital that we not only have world-class resources and expertise but that we are also honest, dedicated and transparent in everything we do.</h6>
                    </div>
                    <div className="col-12 col-md-6 row mx-auto text-center">
                        <h5 className="color-brand-orange my-5">Our Three T's</h5>
                        <div className="col mx-3 individual-principles">
                            <img className="image-correction" src={time} alt="time" />
                            <p className="mt-3 color-brand-orange">Time</p>
                        </div>
                        <div className="col mx-3 individual-principles">
                            <img className="image-correction" src={trust} alt="trust" />
                            <p className="mt-3 color-brand-orange">Trust</p>
                        </div>
                        <div className="col mx-3 individual-principles">
                            <img className="image-correction" src={transparency} alt="transparency" />
                            <p className="mt-3 color-brand-orange">Transparency</p>
                        </div>
                        <h6 className="col-10 mx-auto mt-4">We are intent on adhering to the deadlines while focusing our attention to the minor detail of any of our projects. Our persistence in constant training and capacity building of our personnel has allowed us to win the trust of our clients and establish strong partnerships with all our customers. This entrepreneurial attitude has also helped us foster closer working relationships, which we have seen consistently lead to enhanced levels of services and a more effective engineering outcome.</h6>
                    </div> */}
                    <div className="col-12 bow-tie-con mb-5">

                    </div>
                    <div className="col bow-tie-info">
                        <h6 className="col-12 col-md-10 mx-auto mt-4">We believe that to enhance our brand as a major oil & gas solutions company, it is vital that we not only have world-class resources and expertise but that we are also honest, dedicated and transparent in everything we do.</h6>
                    </div>
                    <div className="col bow-tie-info">
                        <h6 className="col-12 col-md-10 mx-auto mt-4">We are intent on adhering to the deadlines while focusing our attention to the minor detail of any of our projects. Our persistence in constant training and capacity building of our personnel has allowed us to win the trust of our clients and establish strong partnerships with all our customers. This entrepreneurial attitude has also helped us foster closer working relationships, which we have seen consistently lead to enhanced levels of services and a more effective engineering outcome.</h6>
                    </div>
                </div>
                {/* <div className="col-12 mx-auto mx-0 py-5 mt-5 vision-mission">
                    <div className="col-12 col-md-9 row mx-0 mx-auto">
                        <div className="col-12 col-md vision-mission-con row mx-auto text-center">
                            <h5 className="mb-2">Our Vision <img className="ms-3" src={vision} alt="vision" /></h5>
                            <p className="mt-3">Our Vision is to be Africa's leading energy servicing company.</p>
                        </div>
                        <div className="col-12 col-md vision-mission-con row mx-auto text-center">
                            <h5 className="mb-2">Our Mission <img className="ms-3" src={mission} alt="mission" /></h5>
                            <p className="mt-3">Our Mission is to ensure sustained growth, profitability and customer confidence, by continually advancing the performance of our operations and the quality of our service delivery.</p>
                        </div>
                    </div>
                </div> */}
            </div>
            {/* <div className="domain-solution col-12 col-md-11 row mx-0 my-5 mx-auto">
                <div className="col-12 col-md border order-2 order-md-1 domain-solution-image">

                </div>
                <div className="col-12 col-md domain-solution-text order-1 order-md-2 pt-5">
                    <div className="col-12 ps-5">
                        <h1 className="section-title mb-5">Our Domain <span classname="">Solution</span></h1>
                        <h2 className="">Water &amp; Process Technologies</h2>
                        <h6 className="col-10 mt-3">We are intent on adhering to the deadlines while focusing our attention to the minor detail of any of our projects. Our persistence in constant training and capacity building of our personnel has allowed us to win the trust of our clients and establish strong partnerships with all our customers. This entrepreneurial attitude has also helped us foster closer working relationships, which we have seen consistently lead to enhanced levels of services and a more effective engineering outcome.</h6>
                        <Link to="/water-process" className="nav-link" href="#">
                            <button className="btn btn-link mt-4">Learn More</button>
                        </Link>
                    </div>
                </div>
            </div> */}
            <div ref={managementRef} className="management">
                <div className="col-12 text-center mt-5">
                    <h1 className="section-title mb-5">Our <span classname="">Leadership</span></h1>
                    <h2 className="col-11 col-md-10 col-lg-9 mx-auto text-left my-4">Board</h2>
                    <div className="col-11 col-lg-10 col-xl-9 mx-auto row">
                        <div data-bs-toggle="modal" data-bs-target="#daereModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-gmd px-0 text-center">
                                <img src={gmd} alt="gmd"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Daere Akobo</h1>
                                <span className="mb-4">Founder/Group Managing Director</span> <br></br>
                            </div>
                            {/* <div className=" d-none col-12 px-5 text-center">
                                <h1 className="management-title mb-2">Daere Akobo</h1>
                                <span className="mb-4">Founder/Group Managing Director</span> <br></br>
                            </div> */}
                        </div>
                        <div data-bs-toggle="modal" data-bs-target="#iyenemiModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-iyenemi px-0 text-center">
                                <img src={iyenemi} alt="iyenemi"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Iyenemi Akobo</h1>
                                <span className="mb-4">GVP, Global Business Services</span> <br></br>
                            </div>
                            {/* <div className=" d-none col-12 px-5 text-center">
                                <h1 className="management-title mb-2">Iyenemi Akobo</h1>
                                <span className="mb-4">Group VP, Corporate Services</span> <br></br>
                            </div> */}
                        </div>
                        <div data-bs-toggle="modal" data-bs-target="#lawrenceModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-lawrence px-0 text-center">
                                <img src={lawrence} alt="lawrence"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Lawrence Obhiose</h1>
                                <span className="mb-4">Director, Finance</span> <br></br>
                            </div>
                        </div>
                        {/* <div data-bs-toggle="modal" data-bs-target="#billModal" className="col-11 col-md-6 col-lg mx-2 px-0 management-individual">
                            <div className="management-image management-bill px-0 text-center">
                                <img src={lawrence} alt="lawrence"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Bill Ebereonwu</h1>
                                <span className="mb-4">Non-Executive Director</span> <br></br>
                            </div>
                        </div> */}
                    </div>
                    <h2 className="col-11 col-md-9 mx-auto text-left my-4">Management</h2>
                    <div className="col-11 col-md-9 mx-auto row">
                        <div data-bs-toggle="modal" data-bs-target="#daisyModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-daisy px-0 text-center">
                                <img src={daisy} alt="daisy"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Daisy Maduagwu</h1>
                                <span className="mb-4">VP, PE Energy Ltd</span> <br></br>
                            </div>
                        </div>
                        {/* <div data-bs-toggle="modal" data-bs-target="#vargheseModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-varghese px-0 text-center">
                                <img src={varghese} alt="varghese"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Varghese Lonappan</h1>
                                <span className="mb-4">VP, PE Energy Limited</span> <br></br>
                            </div>
                        </div> */}
                        <div data-bs-toggle="modal" data-bs-target="#nnekaModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-nneka px-0 text-center">
                                <img src={nneka} alt="nneka"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Nneka Okani</h1>
                                <span className="mb-4">VP, Legal and Compliance</span> <br></br>
                            </div>
                        </div>
                        <div data-bs-toggle="modal" data-bs-target="#elenaModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-elena px-0 text-center">
                                <img src={elena} alt="elena"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Elena Papanikolaou</h1>
                                <span className="mb-4">Director, Strategic Partnerships</span> <br></br>
                            </div>
                        </div>
                        {/* <div data-bs-toggle="modal" data-bs-target="#ashokModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-ashok px-0 text-center">
                                <img src={ashok} alt="ashok"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Ashok Nayak</h1>
                                <span className="mb-4">Deputy Managing Director Commercial</span> <br></br>
                            </div>
                        </div> */}
                        {/* <div data-bs-toggle="modal" data-bs-target="#tolaniModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-tolani px-0 text-center">
                                <img src={lawrence} alt="lawrence"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Tolani Kabiru Basorun</h1>
                                <span className="mb-4">Service Director/CoE Manager</span> <br></br>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-11 col-md-9 mx-auto row">
                        {/* <div data-bs-toggle="modal" data-bs-target="#josiahModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-josiah px-0 text-center">
                                <img src={lawrence} alt="lawrence"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Josiah Igoni</h1>
                                <span className="mb-4">P & L Lead - Valves & Actuation</span> <br></br>
                            </div>
                        </div> */}

                        {/* <div data-bs-toggle="modal" data-bs-target="#stephenModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-stephen px-0 text-center">
                                <img src={lawrence} alt="lawrence"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Stephen Akpotoboro</h1>
                                <span className="mb-4">P & L Lead - Exploration & Production</span> <br></br>
                            </div>
                        </div> */}
                        {/* <div data-bs-toggle="modal" data-bs-target="#tonyeModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-dango px-0 text-center">
                                <img src={dango} alt="dango"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Tamunotonye Bapakaye</h1>
                                <span className="mb-4">Snr. Executive Assistant to the GMD</span> <br></br>
                            </div>
                        </div> */}
                        {/* <div data-bs-toggle="modal" data-bs-target="#nwularyModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-nwulary px-0 text-center">
                                <img src={nwulary} alt="nwulary"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Nwulary Nwokeka</h1>
                                <span className="mb-4">Assistant Company Secretary/Legal & Compliance Lead</span> <br></br>
                            </div>
                        </div> */}
                        {/* <div data-bs-toggle="modal" data-bs-target="#chidiebeleModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-chidiebele px-0 text-center">
                                <img src={chidiebele} alt="chidiebele"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Chidiebele Uzoagba</h1>
                                <span className="mb-4">Executive Assistant to the GMD/Sustainability Lead</span> <br></br>
                            </div>
                        </div> */}
                        {/* <div data-bs-toggle="modal" data-bs-target="#charlesModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-charles px-0 text-center">
                                <img src={charles} alt="charles"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Charles Uzoije</h1>
                                <span className="mb-4">Country Manager DK-Lok</span> <br></br>
                            </div>
                        </div> */}
                        {/* <div data-bs-toggle="modal" data-bs-target="#christianModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-christian px-0 text-center">
                                <img src={christian} alt="christian"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Christian Irimoren</h1>
                                <span className="mb-4">Business Development Manager (Downstream)</span> <br></br>
                            </div>
                        </div> */}
                        <div data-bs-toggle="modal" data-bs-target="#emuesiriModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-emuesiri px-0 text-center">
                                <img src={emuesiri} alt="emuesiri"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Emuesiri Onokare-Owueka</h1>
                                <span className="mb-4">HSE Lead</span> <br></br>
                            </div>
                        </div>
                    </div>
                    <div className="col-11 col-md-9 mx-auto row">
                        {/* <div data-bs-toggle="modal" data-bs-target="#davidModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-david px-0 text-center">
                                <img src={lawrence} alt="lawrence"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">David Okumgba</h1>
                                <span className="mb-4">Project Control Manager</span> <br></br>
                            </div>
                        </div> */}

                    </div>
                    {/* <div className="col-11 col-md-9 mx-auto row">
                        <div data-bs-toggle="modal" data-bs-target="#emuesiriModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-emuesiri px-0 text-center">
                                <img src={emuesiri} alt="emuesiri"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Emuesiri Onokare-Owueka</h1>
                                <span className="mb-4">HSE/Sustainability Lead</span> <br></br>
                            </div>
                        </div>
                        <div data-bs-toggle="modal" data-bs-target="#davidModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-david px-0 text-center">
                                <img src={lawrence} alt="lawrence"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">David Okumgba</h1>
                                <span className="mb-4">Project Control Manager</span> <br></br>
                            </div>
                        </div>
                        <div data-bs-toggle="modal" data-bs-target="#nnekaModal" className="col-11 col-md-3 mx-auto mx-2 px-0 management-individual">
                            <div className="management-image management-nneka px-0 text-center">
                                <img src={nneka} alt="nneka"></img>
                            </div>
                            <div className="management-hover px-0">
                                <h1 className="management-title mb-2">Nneka Okani</h1>
                                <span className="mb-4">VP, Legal and Compliance</span> <br></br>
                            </div>
                        </div>

                    </div> */}
                    <div class="modal fade" id="daereModal" tabindex="-1" aria-labelledby="daereModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Daere Akobo</h1>
                                        <h6>Founder &amp; Group Managing Director</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        <p>Daere Akobo is an entrepreneur and business mentor. He holds the offices of Chairman with PANA Holdings and of GMD with PE Energy Ltd. <br></br><br></br><b></b>

                                            Daere counts over 20 years of experience in the operations of the Oil &amp; Gas sector, across a wide range of the spectrum, including electrical, instrumentation, condition and vibration monitoring, flow metering processes and procurement. <br></br><br></br><b></b> In his early career, Daere has worked with the International Petroleum Company of Nigeria as an Electrical Instrumentation Superintendent before moving-on to TOTAL E&amp;P as a Technical Stock Controller supporting the Procurement Department.<br></br><br></br><b></b> During his employment with General Electric Nigeria, he stood-out for his business acumen (he was able to quadruple the company's sales during 2007-8), as well as for his social skills (it was repeatedly due to his intervention that disputes between clients and partners of the company were resolved) and was eventually crowned with the award of ''top talent'' throughout the African continent. <br></br><br></br><b></b>

                                            At that point, he departed on his own business venture and in 2009, after realizing the need to bridge the gap between user expectations and market offerings in the Nigerian Oil &amp; Gas industry at the time, he founded Plant Engineering Limited, which was later-on renamed to PE Energy Limited. <br></br><br></br><b></b> PE Energy soon became recognized for its unassailable integrity and for the attention it lends to regulatory compliance and standards. <br></br><br></br><b></b> Daere has made PE Energy an exemplary part of the NCD program, ensuring not only strict compliance with all applicable standards, but setting the bar even higher, through the construction of a Center of Excellence (CoE) in Trans Amadi. <br></br><br></br><b></b> Through a wide range of prestigious business alliances, Daere has been strengthening the company's international business footprint, leading it with a steady pace to a course of solid, sustainable growth. <br></br><br></br><b></b>

                                            Daere holds a degree in Applied Physics and is a distinguished alumnus of the prestigious Harvard Business School, the INSEAD Business School and the Manchester Business School. He has been honored with several excellence awards by many European business partners (e.g. the ''Super Vendor Delivery Performance Award'' by Shell, the “Honeywell EMEA Best Sales Award” etc.) and has risen to become a trusted point of reference for many international businesses wishing to enter Nigeria through association with a local party.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="iyenemiModal" tabindex="-1" aria-labelledby="iyenemiModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Iyenemi Akobo</h1>
                                        <h6>GVP, Global Business Services</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        <p>Iyenemi has been the backbone of the entire organization since its inception. With a background in Business Administration and CLE training in various international institutions in London, Dubai, Switzerland and Harvard. She is an alumina of the prestigious IMD Business School. <br></br><br></br>
                                            She has become a central figure in the administration of the group and a valued contributor to the growth of the business structure. She has held and efficiently managed key positions enabling and spurring the growth vision of the Chairman.<br></br><br></br>
                                            She is the Vice President responsible for the Corporate Services Unit, additionally supervising the HR, Commercial Services and part of the Accounting functions. She is also driving the implementation of all Corporate Governance requirements on the ground together with a dedicated team of professionals.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="elenaModal" tabindex="-1" aria-labelledby="elenaModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Elena Papanikolaou</h1>
                                        <h6>Director, Strategic Partnerships</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        <p>Dr. Elena Papanikolaou is in charge of international Strategic Partnerships for PANA Holdings, where she has been working as a Program Director managing various projects since 2018. <br></br><br></br>

                                            She has a strong academic background, holding a BA – 1st class in Analytical Philosophy from the University of Cambridge, MA in Classics from the University of Cambridge, MMus from Codarts Hogeschool voor de Kunsten in Rotterdam, PhD in Philosophy from the National University of Athens where she also successfully completed her Post Doctoral research and taught as an assistant lecturer for 10 years. She is located in Athens, Greece, and she speaks Greek, English, French and German.<br></br><br></br>



                                            Elena has a longstanding experience in working within different cultural backgrounds, a strong sense of organization, demonstrated effectiveness in managing multiple cross-functional responsibilities and demonstrated ability to successfully collaborate with stakeholders at all levels to deliver results. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="lawrenceModal" tabindex="-1" aria-labelledby="lawrenceModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Lawrence Obhiose</h1>
                                        <h6>Director, Finance</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        <p>Lawrence Obhiose is an experienced and seasoned financial service professional with extensive corporate experience in the areas of: Private Partnership Advisory, Project Finance, Capital Raising, Strategic and Commercial Due Diligence. He currently oversees the financial strategies and activities as well as developing tools and system to provide critical financial and operational information to the management and make actionable recommendations on both strategy and operations.<br></br><br></br><b></b> Lawrence Obhiose started his career with Details Consulting Limited where he was a senior consultant. He has held several positions in different sectors such as a Deputy Audit Manager, Admin Manager, Payroll/Finance Analyst, and Finance Manager. Lawrence Obhiose has attended many Management, Leadership and Professional Trainings. He holds a HND in Accountancy and a Bachelor of Science in Finance & Banking.<br></br><br></br><b></b> He is also an Associate member of The Institute of Chartered Accountants of Nigeria (ICAN).</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="daisyModal" tabindex="-1" aria-labelledby="daisyModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Daisy Maduagwu</h1>
                                        <h6>VP, Global Operations</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        <p>Daisy Maduagwu is currently the Vice President responsible for the Global Operations at PANA Holdings Group, actively assisting the Chairman and GCEO. She is also responsible for the Supply Chain Business for the Group.<br></br><br></br><b></b> Daisy has an in-depth knowledge in Strategic Sourcing, general procurement, contracting and negotiation etc. and has been at the forefront of this business unit working with her team of professionals to deliver value added services in supply chain to the oil and gas and non-oil and gas market.<br></br><br></br><b></b> She has a B.Sc. in Economics and Educational management and is currently rounding up her Masters degree in Supply Chain Management.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="igilarModal" tabindex="-1" aria-labelledby="igilarModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Chukwuemeka Igilar</h1>
                                        <h6>Countries Manager</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        <p>Igilar is the Countries Manager responsible for driving customer centric solution delivery. He has extensive experience in driving operational and process excellence for key stakeholders in the Oil & Gas sector through effective sales leadership, strategic coordination of business operations and customer relationships management. <br></br><br></br>
                                            Igilar’s expertise spreads across Automation, Process, Instrumentation and Control solutions. He is also a certified turnaround maintenance, installation & commissioning expert for Valves & Actuators. He holds a Bachelor of Engineering in Mechanical Engineering.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="christianModal" tabindex="-1" aria-labelledby="christianModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Christian Irimoren</h1>
                                        <h6>Business Development Manager (Downstream)</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="charlesModal" tabindex="-1" aria-labelledby="charlesModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Charles Uzoije</h1>
                                        <h6>Country Manager DK-Lok</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        <p>Charles  is the country manager for DK-LOK , he is  driving DK-LOK business. He has extensive experiences in oil and gas maintenance operation and services. He has worked on different offshore deep water FPSO’S locations. He coordinate the business operations, which covers sales and services.



                                            His expertise covers  across electrical and Instrumentation. He is also certified maintenance, pre-commissioning and commissioning, installations, troubleshooting field engineer. He is a graduate of electrical and electronic engineer.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="davidModal" tabindex="-1" aria-labelledby="davidModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">David Okumgba</h1>
                                        <h6>Project Control Manager</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="billModal" tabindex="-1" aria-labelledby="billModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Bill Ebereonwu</h1>
                                        <h6>Non-Executive Director</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        {/* <p>David Okungba is the Project Control Manager leading the entire execution team of projects from Order-To-Remittance (OTR). He is responsible for all Maintenance, Repair & Operations (MRO) and Engineering, Procurement & Construction (EPC) Purchase Orders (POs). He prepares and maintains accurate record of POs, technical library management, while being responsible for the overall direction, coordination, implementation, execution, control and completion of company projects.<br></br><br></br><b></b> David’s expertise spreads across Supply Chain and Project Management and by closely working with his team, he has optimally managed & delivered key projects.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="tolaniModal" tabindex="-1" aria-labelledby="tolaniModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Tolani Kabiru Basorun</h1>
                                        <h6>Service Director / CoE Manager</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        <p>Basorun Kabiru Tolani holds a B. Sc. Electronic & Electrical Engineering from Obafemi Awolowo University (OAU).<br></br><br></br>
                                            He is a seasoned System and Instrumentations Engineer with 28 years experience majoring in the Oil and gas industry. Prior to Joining PE
                                            Energy, he worked with Buromat Data Sys.Nig. Ltd, Interdrill Nigeria Limited, Kragha & Associates Limited, Tranter International Limited,
                                            Sentrex Limited, Albert-Garaudy & Assocites, Crestville Eng.& Tech. Co Ltd and Saipem Contracting. Nig. Ltd.<br></br><br></br>
                                            Tolani is a member of the Nigerian Society of Engineers (MNSE) and Registered by the Council for the Regulation of Engineering in Nigeria
                                            (COREN).<br></br><br></br>
                                            He is a well-rounded Project Manager with extensive training in Quality Management System, Emergency Response Awareness, Leadership in
                                            Safety as well as SmartPlant Instrumentation.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="chidiebeleModal" tabindex="-1" aria-labelledby="chidiebeleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Chidiebele Uzoagba</h1>
                                        <h6>Executive Assistant to the GMD/Sustainability Lead</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        {/* <p>David Okungba is the Project Control Manager leading the entire execution team of projects from Order-To-Remittance (OTR). He is responsible for all Maintenance, Repair & Operations (MRO) and Engineering, Procurement & Construction (EPC) Purchase Orders (POs). He prepares and maintains accurate record of POs, technical library management, while being responsible for the overall direction, coordination, implementation, execution, control and completion of company projects.<br></br><br></br><b></b> David’s expertise spreads across Supply Chain and Project Management and by closely working with his team, he has optimally managed & delivered key projects.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="josiahModal" tabindex="-1" aria-labelledby="josiahModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Josiah Igoni</h1>
                                        <h6>P & L Lead - Valves & Actuation</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        {/* <p>David Okungba is the Project Control Manager leading the entire execution team of projects from Order-To-Remittance (OTR). He is responsible for all Maintenance, Repair & Operations (MRO) and Engineering, Procurement & Construction (EPC) Purchase Orders (POs). He prepares and maintains accurate record of POs, technical library management, while being responsible for the overall direction, coordination, implementation, execution, control and completion of company projects.<br></br><br></br><b></b> David’s expertise spreads across Supply Chain and Project Management and by closely working with his team, he has optimally managed & delivered key projects.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="stephenModal" tabindex="-1" aria-labelledby="stephenModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Stephen Akpotoboro</h1>
                                        <h6>P & L Lead - Exploration & Production</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        {/* <p>David Okungba is the Project Control Manager leading the entire execution team of projects from Order-To-Remittance (OTR). He is responsible for all Maintenance, Repair & Operations (MRO) and Engineering, Procurement & Construction (EPC) Purchase Orders (POs). He prepares and maintains accurate record of POs, technical library management, while being responsible for the overall direction, coordination, implementation, execution, control and completion of company projects.<br></br><br></br><b></b> David’s expertise spreads across Supply Chain and Project Management and by closely working with his team, he has optimally managed & delivered key projects.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="emuesiriModal" tabindex="-1" aria-labelledby="emuesiriModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Emuesiri Onokare-Owueka</h1>
                                        <h6>HSE Lead</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        Dr. Emuesiri Onokare-Owueka is the sustainability lead at Synergy E & P, a subsidiary of Pana Holdings. She is focused on the development of strategies for the identification and provision of products and services that promote low-carbon emissions across the oil and gas value chain and the reduction of GHG emissions to accelerate the global energy transition for the Oil and Gas industry to enhance decarbonization.<br></br><br></br>  Dr. Emuesiri has over seven (7) years of experience within the Oil & Gas sector in Health, Safety, and Environmental Management.

                                        Dr. Emuesiri obtained her Bachelor’s degree in Environmental Biology from the University of Port Harcourtand a Master’s degree in Occupational Health and Safety from the Centre for Occupational Health, Safety, and Environment under the Institute of Petroleum Studies (IPS) at the University of Port Harcourt. <br></br><br></br>Her doctoral degree was undertaken in Environmental Technology and Management at the World Bank African Centre for Oil Field Research, University of Port Harcourt. She is a certified Environmental Specialist and a member of the National Registry of Environmental Professionals, (NREP) USA, and the Institute of Safety Professionals of Nigeria (ISPON).
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="ugoModal" tabindex="-1" aria-labelledby="ugoModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Ugochukwu Nwoko</h1>
                                        <h6>P & L Lead - Automation & Control</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        {/* <p>David Okungba is the Project Control Manager leading the entire execution team of projects from Order-To-Remittance (OTR). He is responsible for all Maintenance, Repair & Operations (MRO) and Engineering, Procurement & Construction (EPC) Purchase Orders (POs). He prepares and maintains accurate record of POs, technical library management, while being responsible for the overall direction, coordination, implementation, execution, control and completion of company projects.<br></br><br></br><b></b> David’s expertise spreads across Supply Chain and Project Management and by closely working with his team, he has optimally managed & delivered key projects.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="nwularyModal" tabindex="-1" aria-labelledby="nwularyModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Nwulary Nwokeka</h1>
                                        <h6>Assistant Company Secretary/Legal & Compliance Lead</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        <p>Nwulary Nwokeka holds a bachelor’s degree in Law and B.L. She is a member of the Nigerian Bar Association and an Associate member of the Institute of Chartered Secretaries and Administrators of Nigeria (ICSAN).
                                            Nwulary is a legal practitioner, boardroom and corporate administrator focusing on corporate governance, risks, compliance, sustainability, investor relations and stakeholder management, corporate regulatory and initiating strategic alliances and partnerships.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="editangModal" tabindex="-1" aria-labelledby="editangModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">David Editang</h1>
                                        <h6>Nigeria Content Development Lead</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        {/* <p>David Okungba is the Project Control Manager leading the entire execution team of projects from Order-To-Remittance (OTR). He is responsible for all Maintenance, Repair & Operations (MRO) and Engineering, Procurement & Construction (EPC) Purchase Orders (POs). He prepares and maintains accurate record of POs, technical library management, while being responsible for the overall direction, coordination, implementation, execution, control and completion of company projects.<br></br><br></br><b></b> David’s expertise spreads across Supply Chain and Project Management and by closely working with his team, he has optimally managed & delivered key projects.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="ashokModal" tabindex="-1" aria-labelledby="ashokModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Ashok Nayak</h1>
                                        <h6>Deputy Managing Director Commercial</h6>
                                    </div>
                                    <div className="modal-body px-5">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="tonyeModal" tabindex="-1" aria-labelledby="tonyeModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Tamunotonye Bapakaye</h1>
                                        <h6>Snr. Executive Assistant to the GMD</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        {/* <p>David Okungba is the Project Control Manager leading the entire execution team of projects from Order-To-Remittance (OTR). He is responsible for all Maintenance, Repair & Operations (MRO) and Engineering, Procurement & Construction (EPC) Purchase Orders (POs). He prepares and maintains accurate record of POs, technical library management, while being responsible for the overall direction, coordination, implementation, execution, control and completion of company projects.<br></br><br></br><b></b> David’s expertise spreads across Supply Chain and Project Management and by closely working with his team, he has optimally managed & delivered key projects.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="nnekaModal" tabindex="-1" aria-labelledby="nnekaModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Nneka Okani</h1>
                                        <h6>VP, Legal and Compliance</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        Having 2 decades of experience in the area of Commercial Law with extensive transactional knowledge across the Oil and Gas industry value chain as well as Corporate and Project Finance expertise in energy sector markets, Nneka joins the Pana Holdings Group as its Vice President Legal and Compliance – providing enterprise wide Legal governance, risk management and compliance leadership across the Group’s Strategic Business Units. <br></br><br></br>

                                        Prior to joining the Pana Holdings Group, Nneka was Senior Legal Counsel, Aiteo Eastern Exploration and Production Company Ltd and holds several academic degrees including an MSc. Oil and Gas Trade Management from Regents University, London and MBA Global Business from Coventry University London, United Kingdom.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="vargheseModal" tabindex="-1" aria-labelledby="vargheseModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0 text-center">
                                    <div className="modal-head py-4">
                                        <h1 className="">Varghese Lonappan</h1>
                                        <h6>VP, PE Energy Limited</h6>
                                    </div>
                                    <div className="modal-body px-5">
                                        Varghese Lonappan is our Group Vice President, Middle East & Africa who drives our growth engines pertaining to Flow Drives, Flow Controls, Flow Measurement Devices & Supply Chain Management. He started his career as a Design Engineer for Valves in 1993 and has held responsibilities pertaining to Engineering, Quality Assurance and Sales of Valves & Pumps in various countries which include India, South Africa, GCC & East Africa. <br></br><br></br>

                                        A passionate leader and a strong mentor with a deep conviction that the strength of his team is what will bring out exceptional results. Together with his team, Varghese has been successful in transforming strategic plans into workable solutions with key performance objectives.

                                        Varghese holds a Bachelor’s Degree in Mechanical Engineering and a Master’s in Business Administration.<br></br><br></br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ManagementModal /> */}
            </div>
            <div ref={historyRef} className="col-12 history">
                <h1 className="section-title mb-5 text-center">Our <span classname="">History</span></h1>
                <Splide
                    options={{
                        rewind: true,
                        gap: '1rem',
                        type: 'loop',
                        perPage: 4,
                        breakpoints: {
                            768: {
                                perPage: 1,
                                drag: true,
                                pagination: true
                            },
                        },
                        perMove: 1,
                        autoplay: true,
                        interval: 5000,
                        arrows: false
                    }}
                    aria-label="My Favorite Images" X
                >
                    <SplideSlide>
                        <div className="col-11 mx-auto col-md mx-2 splide-slide-individual">
                            <div className="splide-slide-icon splide-slide-2009 col-12 px-0 text-center">
                                {/* <img className="mx-auto d-block mt-4" src={valvesIcon} alt="valvesIcon"/> */}
                                {/* <img className="mx-auto d-block mt-4" src={valvePhoto} alt="valvesPhoto"/> */}
                            </div>
                            <div className="splide-slide-text col-12 px-4 text-center">
                                <h1 className="splide-slide-title mt-4 mb-2">2009</h1>
                                <span className="growth-engine-title my-2">We realized there was a gap to be bridged</span> <br></br>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="col-11 mx-auto col-md mx-2 splide-slide-individual">
                            <div className="splide-slide-icon splide-slide-2010 col-12 px-0 text-center">

                            </div>
                            <div className="splide-slide-text col-12 px-4 text-center">
                                <h1 className="splide-slide-title mt-4 mb-2">2010</h1>
                                <span className="growth-engine-title my-2">Going to business, with an initial focus on MRO. Completing our first order, at 54 Emekuku Street, PHC.</span> <br></br>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="col-11 mx-auto col-md mx-2 splide-slide-individual">
                            <div className="splide-slide-icon splide-slide-2012 col-12 px-0 text-center">

                            </div>
                            <div className="splide-slide-text col-12 px-4 text-center">
                                <h1 className="splide-slide-title mt-4 mb-2">2012</h1>
                                <span className="growth-engine-title my-2">The year when we started to Think, Plan, Act and Grow.</span> <br></br>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="col-11 mx-auto col-md mx-2 splide-slide-individual">
                            <div className="splide-slide-icon splide-slide-2013 col-12 px-0 text-center">

                            </div>
                            <div className="splide-slide-text col-12 px-4 text-center">
                                <h1 className="splide-slide-title mt-4 mb-2">2013</h1>
                                <span className="growth-engine-title my-2">In the year 2013, Lagos Office was established</span> <br></br>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="col-11 mx-auto col-md mx-2 splide-slide-individual">
                            <div className="splide-slide-icon splide-slide-2015 col-12 px-0 text-center">

                            </div>
                            <div className="splide-slide-text col-12 px-4 text-center">
                                <h1 className="splide-slide-title mt-4 mb-2">2015</h1>
                                <span className="growth-engine-title my-2">Successful partnership with leading OEMs, beginning to fulfill the Local Content aspiration, rebranding of our headquarters</span> <br></br>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="col-11 mx-auto col-md mx-2 splide-slide-individual">
                            <div className="splide-slide-icon splide-slide-2017 col-12 px-0 text-center">

                            </div>
                            <div className="splide-slide-text col-12 px-4 text-center">
                                <h1 className="splide-slide-title mt-4 mb-2">2017</h1>
                                <span className="growth-engine-title my-2">Launched our VACC workshop</span> <br></br>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="col-11 mx-auto col-md mx-2 splide-slide-individual">
                            <div className="splide-slide-icon splide-slide-2019 col-12 px-0 text-center">

                            </div>
                            <div className="splide-slide-text col-12 px-4 text-center">
                                <h1 className="splide-slide-title mt-4 mb-2">2019</h1>
                                <span className="growth-engine-title my-2">	Established our Abuja office</span> <br></br>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="col-11 mx-auto col-md mx-2 splide-slide-individual">
                            <div className="splide-slide-icon splide-slide-2021 col-12 px-0 text-center">

                            </div>
                            <div className="splide-slide-text col-12 px-4 text-center">
                                <h1 className="splide-slide-title mt-4 mb-2">2021</h1>
                                <span className="growth-engine-title my-2">The PE Centre of Excellence at Trans Amadi Facility was launched.</span> <br></br>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="col-11 mx-auto col-md mx-2 splide-slide-individual">
                            <div className="splide-slide-icon splide-slide-2022 col-12 px-0 text-center">

                            </div>
                            <div className="splide-slide-text col-12 px-4 text-center">
                                <h1 className="splide-slide-title mt-4 mb-2">2022</h1>
                                <span className="growth-engine-title my-2">PE Business model moved from being a supply chain and Project Management to an EP<span className="orange-text">ii</span>Mtce model</span> <br></br>
                            </div>
                        </div>
                    </SplideSlide>

                </Splide>
                <h1 ref={policiesRef} className="section-title mb-5 text-center">Our <span classname="">Policies</span></h1>
                <div className="col-10 row mx-auto mx-0">
                    <div className="col policy mb-4 text-center">
                        <a href={qualitypdf} target="_blank" rel="noreferrer">
                            <img src={quality} alt="quality"></img>
                        </a>
                    </div>
                    <div className="col policy mb-4 text-center">
                        <a href={ncdpdf} target="_blank" rel="noreferrer">
                            <img src={ncd} alt="ncd"></img>
                        </a>
                    </div>
                    <div className="col policy mb-4 text-center">
                        <a href={cashespdf} target="_blank" rel="noreferrer">
                            <img src={cashes} alt="cashes"></img>
                        </a>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default AboutUsPage;
